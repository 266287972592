import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/product'
export default {

  saveOrUpdate(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  toggleEnabled(data) {
    return axios.post(`${prefixPath}/toggleEnabled`, data)
  },

  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },

  getSkuPages(data) {
    return axios.post(`${prefixPath}/getSkuPages`, data)
  },

  delete(id) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      id,
    }))
  },

  modifySkuWarehouse(skuId, warehouseId) {
    return axios.post(`${prefixPath}/modifySkuWarehouse`, qs.stringify({
      skuId,
      warehouseId,
    }))
  },

  modifySkuRemark(skuId, remark) {
    return axios.post(`${prefixPath}/modifySkuRemark`, qs.stringify({
      skuId,
      remark,
    }))
  },

  modifySkuUnit(data) {
    return axios.post(`${prefixPath}/modifySkuUnit`, data)
  },

  getSearchList() {
    return axios.get(`${prefixPath}/getSearchList`)
  },

  getProductDeatil(id) {
    return axios.get(`${prefixPath}/getProduct`, {
      params: {
        id,
      },
    })
  },

  getProductSkuSimple(id) {
    return axios.get(`${prefixPath}/getProductSkuSimple`, {
      params: {
        id,
      },
    })
  },

  getSkuGroupBySkuId(skuId) {
    return axios.get(`${prefixPath}/getSkuGroupBySkuId`, {
      params: {
        skuId,
      },
    })
  },

}
