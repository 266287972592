<template>
  <div v-if="$can('promote_list', 'promote')">
    <v-card>
      <v-form
        ref="searchForm"
        @submit.prevent="toSearch()"
      >
        <v-card-text class="d-flex flex-row-reverse pb-0">
          <div
            v-if="$can('promote_save', 'promote')"
            class="d-flex align-center"
          >
            <v-btn
              color="primary"
              class="me-3"
              @click="editItem()"
            >
              <span>新建促销</span>
            </v-btn>
          </div>

          <div class="d-flex align-center">
            <v-btn
              class="me-3"
              @click="resetSearch()"
            >
              <span>重置</span>
            </v-btn>
          </div>

          <div class="d-flex align-center">
            <v-btn
              color="secondary"
              class="me-3"
              type="submit"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              <span>查询</span>
            </v-btn>
          </div>

          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <v-text-field
              v-model="search.name"
              outlined
              dense
              label="标题"
            ></v-text-field>
          </div>
          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <DatePick
              v-model="search.endTime"
              :hide="false"
              label="结束时间"
            ></DatePick>
          </div>
          <div class="d-flex align-center mr-5">
            <span>至</span>
          </div>

          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <DatePick
              v-model="search.startTime"
              :hide="false"
              label="开始时间"
            ></DatePick>
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card class="mt-2">
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true
          }"
          disable-filtering
          :headers="getHeader()"
          :items="promotes"
          :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength"
          no-data-text="无数据"
          @pagination="loadPage"
        >
          <template #[`item.title`]="{ item }">
            <v-row>
              <v-col
                class="pa-2 align-self-center"
                cols="auto"
              >
                <img
                  style="max-width: 45px"
                  :src="item.promoteImg"
                >
              </v-col>
              <v-col class="pl-0">
                <span class="text-subtitle-2">{{ item.title }}  </span><br>
                <span
                  class="text-caption"
                >{{ item.startTime }}-{{ item.endTime }}</span>
              </v-col>
            </v-row>
          </template>

          <template #[`item.link`]="{ item }">
            <a
              v-if="item.link"
              :href="`${item.link}`"
              target="_blank"
            >
              <span v-if="item.bannerType === false">{{ item.link }}</span>
            </a>
          </template>

          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <v-btn
              v-if="$can('promote_edit', 'promote')"
              text
              dense
              x-small
              color="primary"
              @click="editItem(item)"
            >
              编辑
            </v-btn>
            <v-btn
              v-if="$can('promote_delete', 'promote')"
              text
              dense
              x-small
              color="primary"
              @click="showDeleteDialog(item)"
            >
              删除
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '80%' : '100%'"
      height="100%"
      app
    >
      <v-card
        height="100%"
      >
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}促销活动</span>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-5"
            :disabled="commitFlag"
            :loading="commitFlag"
            @click="handleFormSubmit()"
          >
            保存
          </v-btn>
          <v-btn
            icon
            small
            @click="editDialog = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text
          style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
          class="overflow-auto"
        >
          <v-card>
            <v-card-title>
              <p class="text-h6">
                基本信息
              </p>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="editForm"
                lazy-validation
                @submit.prevent="handleFormSubmit"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-file-input
                      v-model="currentImg"
                      :loadings="loadings.bannerUploaderLoading"
                      :disabled="loadings.bannerUploaderLoading"
                      label="上传图片"
                      dense
                      outlined
                      hide-details
                      @change="uploadImage(currentImg)"
                    ></v-file-input>
                    <span class="text-caption mt-2 ml-3">建议尺寸：800*600像素</span>
                    <v-img
                      v-if="editedItem.promoteImg"
                      :src="editedItem.promoteImg"
                      class="mt-1 ml-3"
                      max-height="150"
                      max-width="250"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.title"
                      label="标题*"
                      outlined
                      :rules="rules.required"
                      counter="100"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    class="pa-0"
                  >
                    <DatePick
                      v-model="editedItem.startTime"
                      label="开始时间"
                    ></DatePick>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="pa-0"
                  >
                    <DatePick
                      v-model="editedItem.endTime"
                      :hide="true"
                      label="结束时间"
                    ></DatePick>
                    <span class="text-caption  ml-6">结束促销时间将不再显示促销区</span>
                  </v-col>
                </v-row>

                <!-- 选择商品 多选 自定义插槽 里面应该包含头像 -->
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <!-- 禁止输入的input 带有点击事件 -->
                    <v-btn
                      color="secondary"
                      outlined
                      dense
                      @click="productSelectDialog = true"
                    >
                      选择商品
                    </v-btn>
                    <!-- <v-autocomplete
                      v-model="editedItem.productIds"
                      :items="products"
                      item-text="productName"
                      item-value="id"
                      label="选择商品"
                      multiple
                      outlined
                      dense
                      hide-details

                      :rules="rules.required"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                          v-if="index === 0"
                          label
                          x-small
                          color="primary"
                        >
                          <span>{{ item.productName }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text caption"
                        >(+{{ editedItem.productIds.length - 1 }} others)</span>
                      </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        :size="30"
                        :tile="true"
                      >
                        <v-img
                          :src="item.productImg"
                          :alt="item.productName"
                          :lazy-src="item.productImg"
                          height="30"
                          width="30"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.productName"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    </v-autocomplete> -->
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
      <ProductSelect
        v-model="productSelectDialog"
        :selected-product="selectedProduct"
        :is-update="editedItem.id"
        @getSelect="getSelectProduct"
      ></ProductSelect>
    </v-navigation-drawer>
    <v-dialog
      v-model="deleteDialog"
      width="300"
    >
      <v-card>
        <v-card-text> 确定删除促销:<span style="color: red">{{ deleteData.title }}</span>?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItem()"
          >
            确认删除
          </v-btn>
          <v-btn
            color="normal"
            text
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiLinkVariant,
} from '@mdi/js'
import DatePick from '@/components/DatePicker'
import PromoteApi from '@/api/market/promote'
import ProductSelect from '@/components/select/productSelect'
import uploadImg from '@/api/upload'

export default {
  name: 'Promote',
  components: {
    DatePick,
    ProductSelect,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiLinkVariant,
      },
      editDialog: false,
      deleteDialog: false,
      productSelectDialog: false,
      rules: {
        required: [v => !!v || '该字段不能为空'],
      },
      loadings: {
        submitLoading: false,
        bannerUploaderLoading: false,
      },
      promotes: [
      ],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      search: {},
      editedItem: {
        startTime: '',
        endTime: '',
      },
      editedIndex: -1,
      defaultItem: {
        id: undefined,
        title: '',
        promoteImg: '',
        startTime: '',
        endTime: '',
      },
      deleteData: {},
      currentImg: {},
      products: [],
      selectedProduct: [],
      commitFlag: false,
    }
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '促销标题', value: 'title' },
        { text: '创建时间', value: 'createdTime' },
      ]

      if (this.$can('promote_delete', 'promote')
          || this.$can('promote_edit', 'promote')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSearch() {
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    loadPage({ page, itemsPerPage }) {
      let startTime = ''
      if (this.search.startTime) {
        startTime = `${this.search.startTime} 00:00:00`
      }
      let endTime = ''
      if (this.search.endTime) {
        endTime = `${this.search.endTime} 23:59:59`
      }
      const searchData = {
        rows: itemsPerPage,
        page,
        startTime,
        endTime,
        name: this.search.name,
        isEnabled: this.status,
      }
      PromoteApi.getPages(searchData)
        .then(response => {
          const { data } = response.data
          this.promotes = data.records
          if (data.size === -1) {
            this.pages.serverItemsLength = this.promotes.length
          } else {
            this.pages.serverItemsLength = data.total
          }
        })
    },
    editItem(item) {
      this.currentImg = {}
      const id = item ? item.id : -1
      if (this.$can('promote_detail', 'promote')) {
        PromoteApi.getDetail(id).then(res => {
          const { detailDTO, productDTOS } = res.data.data

          if (item) {
            this.editedIndex = this.promotes.indexOf(item)

            this.editedItem = detailDTO
            this.selectedProduct = productDTOS
          } else {
            this.editedIndex = -1

            this.editedItem = { ...this.defaultItem }
            this.$nextTick(() => {
              this.$refs.editForm.resetValidation()
            })
          }
          this.commitFlag = false
          this.editDialog = true
        })
      }
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      if (this.commitFlag) return
      this.loadings.submitLoading = true

      const submitData = {
        promote: {
          id: this.editedItem.id,
          title: this.editedItem.title,
          promoteImg: this.editedItem.promoteImg,
          startTime: this.editedItem.startTime,
          endTime: this.editedItem.endTime,
        },
        productIds: this.editedItem.productIds,
      }

      if (!this.editedItem.startTime.endsWith('00:00:00')) {
        submitData.promote.startTime = `${this.editedItem.startTime} 00:00:00`
      }
      if (!this.editedItem.endTime.endsWith('23:59:59')) {
        submitData.promote.endTime = `${this.editedItem.endTime} 23:59:59`
      }
      this.commitFlag = true
      PromoteApi.saveOrUpdate(submitData)
        .then(response => {
          this.$toast.success('保存成功')
          if (this.editedIndex > -1) {
            Object.assign(this.promotes[this.editedIndex], this.editedItem)
          } else {
            this.loadPage(this.pages)
          }
          this.commitFlag = false
          this.editDialog = false
        })
        .finally(() => {
          this.loadings.submitLoading = false
        })
    },
    showDeleteDialog(item) {
      this.deleteData = item
      this.deleteDialog = true
    },
    deleteItem() {
      PromoteApi
        .delete(this.deleteData.id)
        .then(() => {
          this.$toast.success('删除成功！')
          this.deleteData = {}
          this.loadPage(this.pages)
          this.deleteDialog = false
        })
    },
    reloadTab() {
      if (this.currentTab === 2) {
        this.status = undefined
      } else if (this.currentTab === 1) {
        this.status = 0
      } else if (this.currentTab === 0) {
        this.status = 1
      }
      this.toSearch()
    },
    uploadImage(img) {
      if (img) {
        this.loadings.bannerUploaderLoading = true

        uploadImg
          .uploadImg(img)
          .then(res => {
            this.editedItem.promoteImg = res.data.data
          })
          .finally(() => {
            this.loadings.bannerUploaderLoading = false
          })
      }
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.search.startTime = ''
      this.search.endTime = ''
      this.toSearch()
    },
    getSelectProduct(selected) {
      let productIds = []
      if (selected) {
        productIds = selected.map(item => item.id)
        this.selectedName = selected.map(item => item.productName)
      }
      this.editedItem.productIds = productIds
    },
  },
}
</script>
