<template>
  <v-dialog
    v-model="shown"
    persistent
    scrollable
    max-width="1500px"
  >
    <v-card>
      <v-form ref="searchForm">
        <v-card-title class="d-flex flex-row-reverse">
          <v-form class="d-flex align-center pt-3">
            <div class="d-flex align-center pt-3">
              <v-btn
                color="secondary"
                class="me-3"
                @click="toSearch()"
              >
                <v-icon
                  size="18"
                  class="me-1"
                >
                  {{ icons.mdiMagnify }}
                </v-icon>
                <span>查询搜索</span>
              </v-btn>
            </div>
            <div class="d-flex align-center pt-3">
              <v-btn
                class="me-3"
                @click="resetSearch()"
              >
                <span>重置</span>
              </v-btn>
            </div>

            <div class="d-flex align-center pt-10 mr-10">
              <v-text-field
                v-model="search.name"
                outlined
                dense
                label="搜索商品"
              ></v-text-field>
            </div>
            <div
              v-if="$can('product_list_search', 'product')"
              class="d-flex align-center pt-10 mr-10"
            >
              <v-select
                id="id"
                v-model="search.categoryId"
                outlined
                dense
                label="所属分类"
                item-text="name"
                item-value="id"
                :items="categorys"
              ></v-select>
            </div>
          </v-form>
        </v-card-title>
      </v-form>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-data-table
              v-model="selected"
              disable-filtering
              :headers="getHeader()"
              :items="products"
              :items-per-page="pages.itemsPerPage"
              :server-items-length="pages.serverItemsLength"
              show-select
              @item-selected="itemSelected"
              @toggle-select-all="toggleSelectAll"
              @pagination="loadPage"
            >
              <template #[`item.productName`]="{ item }">
                <v-row>
                  <v-col cols="auto">
                    <v-avatar size="33">
                      <img
                        :src="item.productImg"
                        alt="John"
                      >
                    </v-avatar>
                  </v-col>
                  <v-col class="pl-0">
                    <span class="text-subtitle-2">{{ item.productName }}  </span><br>
                    <span
                      v-if="!item.isSingle"
                      class="text-caption"
                    >多规格</span>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="pa-5"
          >
            <h3>已选择商品</h3>
            <span
              v-for="(select, index) in selected"
              :key="index"
            >
              <v-chip
                class="ma-2"
                close
                color="primary"
                :outlined="true"
                @click:close="remove(select)"
              >
                {{ select.productName }}
              </v-chip>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="secondary"
          @click="closeDialog"
        >
          关闭
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="selectdProduct"
        >
          选择
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiMagnify,
} from '@mdi/js'
import ProductApi from '@/api/product/product'
import CategoryApi from '@/api/product/category'

export default {
  name: 'ProductSelect',
  model: {
    prop: 'shown',
    event: 'update:icon-select-dialog',
  },
  props: {
    title: {
      default: '商品选择器',
    },
    shown: {
      required: true,
      type: Boolean,
      default: false,
    },
    selectedProduct: {
      type: Array,
      default: () => [],
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      icons: {
        mdiMagnify,
      },
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      products: [],
      categorys: [],
      selected: [],
      search: {
        categoryId: undefined,
        name: '',
      },
      selectedProducts: [],
    }
  },
  watch: {
    selectedProduct(val) {
      this.selected = val
    },
    isUpdate(val) {
      if (!val) {
        this.selected = []
      }
    },
  },
  mounted() {
    CategoryApi.listAll()
      .then(response => {
        const { data } = response.data

        this.categorys = data
      })
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '#ID', value: 'id' },
        { text: '商品描述', value: 'productName' },
        { text: '库存', value: 'stockNum' },
        { text: '所属分类', value: 'categoryName' },
        { text: '内部编码', value: 'internalCode' },
      ]

      return baseHeader
    },
    toSearch() {
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    resetSearch() {
      this.$refs.searchForm.reset()
    },
    loadPage({ page, itemsPerPage }) {
      const searchData = {
        rows: itemsPerPage,
        page,
        categoryId: this.search.categoryId,
        name: this.search.name,
        status: 1,
      }
      ProductApi.getPages(searchData)
        .then(response => {
          const { data } = response.data

          this.products = data.records

          this.pages.serverItemsLength = data.total
          this.$forceUpdate()
        })
    },
    closeDialog() {
      this.$emit('update:icon-select-dialog', false)
    },
    selectdProduct() {
      this.$emit('update:icon-select-dialog', false)
      this.$emit('getSelect', this.selected)
    },
    remove(item) {
      const current = this.selected.find(obj => obj.id === item.id)
      if (current) {
        this.selected.splice(this.selected.indexOf(current), 1)
        this.$forceUpdate()
      }
    },
    itemSelected(val) {
      const { item, value } = val
      const findItem = this.selected.find(obj => obj.id === item.id)
      if (value) {
        if (!findItem) {
          this.selected.push(item)
        } else {
          this.$toast.error('该商品已添加')
        }
      } else {
        this.remove(item, false)
      }
      this.$forceUpdate()
    },
    toggleSelectAll(val) {
      const { items, value } = val
      items.forEach(item => {
        this.itemSelected({ item, value })
      })
      this.$forceUpdate()
    },
  },
}
</script>
